import React from "react";
import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { refineAnswerSuggestionCall } from "../../../api/prompt-answer-suggestion";
import UseAnswerButton from "./UseAnswerButton";
import "../../taskpane.css";
import DisplayWarning from "../mui/snackbars/DisplayWarning";

interface AnswerProps {
  answerHtml: string;
  conversation: any;
  onRefine: (conversation: any) => any;
}
//
const Answer: React.FC<AnswerProps> = ({ answerHtml, conversation, onRefine }) => {
  const [inputValue, setInputValue] = useState("");
  const [warning, setWarning] = useState(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const refineAnswerSuggestion = async (event) => {
    setInputValue("");
    const updatedConversation = {
      ...conversation,
      messages: conversation?.messages?.concat({ role: "user", content: inputValue }),
    };

    const params = {
      conversation_type: "mail",
      conversation: updatedConversation,
      auth: Office.context.roamingSettings.get("straact_access_token"),
      org: Office.context.roamingSettings.get("karlamail_uid"),
      referrer: "outlook",
    };
    try {
      setWarning({ reason: "Refining message" });
      const refinedConversation: any = await refineAnswerSuggestionCall(params);
      onRefine(refinedConversation); // Callback function - Pass new conversation to root component
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <TextField
        id="refine-input-field"
        label="Refine suggestion"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className="mui-icon-button-wrapper" onClick={refineAnswerSuggestion}>
                <IconButton type="submit">
                  <AutoFixHighIcon className="submit-refinement-button" />
                </IconButton>
              </div>
            </InputAdornment>
          ),
        }}
      />
      <div id="answer-text" className="answer-text" dangerouslySetInnerHTML={{ __html: answerHtml }} />
      <div id="use-answer-button-wrapper">
        <UseAnswerButton answerHtml={answerHtml} conversation={conversation}></UseAnswerButton>
      </div>
      {warning && (
        <DisplayWarning display_message={warning} snackbarHandleClose={() => setWarning(null)}></DisplayWarning>
      )}
    </>
  );
};

export default Answer;
