import getModelVersion from "../../../api/get-model-version";
import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput } from "@mui/material";

const ModelSelect: React.FC<{ models: any[] }> = ({ models }) => {
  const [selectedModelId, setSelectedModelId] = useState(models[0] ? models[0].id : "");

  useEffect(() => {
    const fetchModelVersion = async () => {
      if (selectedModelId) {
        const params = {
          auth: Office.context.roamingSettings.get("straact_access_token"),
          model: selectedModelId,
          creator: Office.context.roamingSettings.get("karlamail_uid"),
        };
        await getModelVersion(params);
      }
    };
    fetchModelVersion();
  }, [selectedModelId]);

  const handleChange = async (event) => {
    const modelID = event.target.value;
    setSelectedModelId(modelID);
  };

  return (
    <FormControl fullWidth>
      <Select
        id="model-selector"
        value={selectedModelId}
        onChange={handleChange}
        MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
      >
        {models &&
          models.map((model) => (
            <MenuItem key={model.id} value={model.id}>
              {model.name} ({model.id})
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ModelSelect;
