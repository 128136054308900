import React, { useState, useEffect } from "react";
import PrimaryButton from "../mui/PrimaryButton";
import DisplayError from "../mui/snackbars/DisplayError";
import DisplaySuccess from "../mui/snackbars/DisplaySuccess";
import AuthenticationService from "../../../api/authentication-service";
import "../../taskpane.css";
import Typography from "@mui/material/Typography";

const AuthenticationForm: React.FC<{ onAuthenticated: () => void; isVisible: boolean }> = ({
  onAuthenticated,
  isVisible,
}) => {
  const [api_key, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [error, setError] = useState<{ reason: string } | null>(null);
  const [message, setMessage] = useState<{ reason: string } | null>(null);

  const authenticationService = new AuthenticationService();

  const handleAuthenticate = async (event) => {
    event.preventDefault();
    await authenticationService.authenticate(
      api_key,
      secretKey,
      (authenticated: Boolean) => {
        authenticated ? onSuccess() : setError({ reason: "Authentication failed" });
      },
      true
    );
  };

  const onSuccess = () => {
    setMessage({ reason: "Successfully authenticated" });
    onAuthenticated();
  };

  const resetForm = () => {
    setApiKey("");
    setSecretKey("");
  };

  useEffect(() => {
    if (isVisible) {
      resetForm();
    }
  }, [isVisible]);

  return (
    <>
      <div id="authentication">
        {isVisible && (
          <form id="authentication-form" onSubmit={handleAuthenticate}>
            <p>Please input the API key and Secret key provided by Karla</p>
            <div className="form-group">
              <input
                type="text"
                id="authentication-form-api-key-field"
                placeholder="API key"
                name="apiKey"
                onChange={(e) => setApiKey(e.target.value)}
              />
              <input
                type="text"
                id="authentication-form-api-key-field"
                placeholder="Secret key"
                name="apiKey"
                onChange={(e) => setSecretKey(e.target.value)}
              />
            </div>
            <div id="authentication-button">
              <PrimaryButton text="Verify" handleType={SubmitEvent} />
            </div>
          </form>
        )}
        {isVisible && (
          <div id="authentication-footer-text">
            <span id="authentication-button">
              <Typography variant="subtitle2" sx={{ color: "gray", fontSize: "0.8rem" }}>
                You can find both keys at:
                <br></br>
                <a href="https://app.karlachat.com/settings" target="_blank">
                  https://app.karlachat.com/settings
                </a>
              </Typography>
            </span>
          </div>
        )}
        {message && (
          <DisplaySuccess display_message={message} snackbarHandleClose={() => setMessage(null)}></DisplaySuccess>
        )}
        {error && <DisplayError display_message={error} snackbarHandleClose={() => setError(null)}></DisplayError>}
      </div>
    </>
  );
};
export default AuthenticationForm;
